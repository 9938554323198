var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container notinthewarehouseboard" }, [
    _c("div", { staticClass: "commoncontent" }, [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap fl" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
              _c("el-autocomplete", {
                staticClass: "input_single w200 mr10",
                attrs: {
                  placeholder: "请输入产品编号",
                  "fetch-suggestions": _vm.querySearchAsync
                },
                on: {
                  select: function($event) {
                    return _vm.handleSelect()
                  }
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("时间：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.value1,
                  callback: function($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "down fr" },
          [
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchclick }
            }),
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-download",
                circle: "",
                title: "下载"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container_table clearfix mt20" }, [
        _c(
          "table",
          {
            staticClass: "contenttab",
            attrs: { cellpadding: "0", cellspacing: "0" }
          },
          [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.notinthewareList, function(item, index) {
                return _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            width: "775",
                            placement: "right",
                            height: "300",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "max-height": "300px",
                                overflow: "auto"
                              }
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticClass: "tableclass prod",
                                  staticStyle: { "text-align": "center" },
                                  attrs: {
                                    border: "0",
                                    cellpadding: "0",
                                    cellspacing: "0"
                                  }
                                },
                                [
                                  _c("thead", [
                                    _c("th", [_vm._v("样本信息")]),
                                    _c("th", [_vm._v("供应商")]),
                                    _c("th", [_vm._v("业务渠道")]),
                                    _c("th", [_vm._v("属性")]),
                                    _c("th", [_vm._v("销售订单")]),
                                    _c("th", [_vm._v("客户名称")]),
                                    _c("th", [_vm._v("客户交期")]),
                                    _c("th", [_vm._v("订单交期")]),
                                    _c("th", [_vm._v("系统订单数量")]),
                                    _c("th", [_vm._v("系统未清订单数量")]),
                                    _c("th", [_vm._v("预留数量")]),
                                    _c("th", [_vm._v("预留数量-订单数量")]),
                                    _c("th", [_vm._v("销售助理")]),
                                    _c("th", [_vm._v("交货状态")])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(item.productlist, function(i) {
                                      return item.productlist != ""
                                        ? _c(
                                            "tr",
                                            { staticStyle: { height: "40px" } },
                                            [
                                              _c("td", [
                                                _vm._v(_vm._s(i.sampleBook))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.supplierName))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.channel))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.attribute))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.saleOrderId))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.customerName))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.orderTime))
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(i.orderUpdateTime)
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.quantity))
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(i.availableInventory)
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    i.inStockSaledInventory
                                                  )
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(i.shippedOutInventory)
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.ownedAgentId))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(i.orderStatus))
                                              ])
                                            ]
                                          )
                                        : _c(
                                            "tr",
                                            { staticStyle: { height: "40px" } },
                                            [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "8" } },
                                                [_vm._v("~~暂无数据~~")]
                                              )
                                            ]
                                          )
                                    }),
                                    0
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.productclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.productCode))]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            width: "",
                            placement: "right",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "tableclass",
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                border: "0",
                                cellpadding: "0",
                                cellspacing: "0"
                              }
                            },
                            [
                              _c("thead", [
                                _c("th", { staticStyle: { width: "75px" } }, [
                                  _vm._v("物料名称")
                                ]),
                                _c("th", { staticStyle: { width: "75px" } }, [
                                  _vm._v("物料品牌")
                                ]),
                                _c("th", { staticStyle: { width: "75px" } }, [
                                  _vm._v("供应商名称")
                                ]),
                                _c("th", { staticStyle: { width: "75px" } }, [
                                  _vm._v("物料成分")
                                ]),
                                _c("th", { staticStyle: { width: "75px" } }, [
                                  _vm._v("延米克重")
                                ]),
                                _c("th", { staticStyle: { width: "82px" } }, [
                                  _vm._v("幅宽")
                                ]),
                                _c("th", { staticStyle: { width: "82px" } }, [
                                  _vm._v("品类")
                                ]),
                                _c("th", { staticStyle: { width: "82px" } }, [
                                  _vm._v("产地")
                                ]),
                                _c("th", { staticStyle: { width: "82px" } }, [
                                  _vm._v("纱支")
                                ]),
                                _c("th", { staticStyle: { width: "82px" } }, [
                                  _vm._v("海关编号")
                                ]),
                                _c("th", { staticStyle: { width: "75px" } }, [
                                  _vm._v("最小起订量")
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(item.meteriallist, function(i) {
                                  return item.meteriallist.length != 0
                                    ? _c(
                                        "tr",
                                        { staticStyle: { height: "40px" } },
                                        [
                                          _c("td", [
                                            _vm._v(_vm._s(i.materialName))
                                          ]),
                                          _c("td", [_vm._v(_vm._s(i.brand))]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.supplierName))
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(i.materialComposition)
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.weightPerMeter))
                                          ]),
                                          _c("td", [_vm._v(_vm._s(i.width))]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.category))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.placeOfMaterialion))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.yarnCount))
                                          ]),
                                          _c("td", [_vm._v(_vm._s(i.hsCode))]),
                                          _c("td", [_vm._v(_vm._s(i.minOrder))])
                                        ]
                                      )
                                    : _c(
                                        "tr",
                                        { staticStyle: { height: "40px" } },
                                        [_c("p", [_vm._v("~~暂无数据~~")])]
                                      )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.meterialclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.materialCode))]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            width: "300",
                            height: "500",
                            placement: "right",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "tableclass",
                              staticStyle: {
                                "text-align": "center",
                                border: "1px solid #CCCCCC"
                              },
                              attrs: {
                                border: "1",
                                cellpadding: "0",
                                cellspacing: "0"
                              }
                            },
                            [
                              _c("thead", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: { width: "130px" },
                                    attrs: { colspan: "1" }
                                  },
                                  [_vm._v("职业装")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: { width: "160px" },
                                    attrs: { colspan: "2" }
                                  },
                                  [_vm._v("零剪")]
                                )
                              ]),
                              _c("thead", [
                                _c("th", [_vm._v("11库")]),
                                _c("th", [_vm._v("51库")]),
                                _c("th", [_vm._v("52库")])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(item.instocklist, function(i) {
                                  return item.instocklist.length != 0
                                    ? _c(
                                        "tr",
                                        { staticStyle: { height: "40px" } },
                                        [
                                          _c("td", [
                                            _vm._v(_vm._s(i.availQuantity1))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.availQuantity2))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.availQuantity3))
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "tr",
                                        { staticStyle: { height: "40px" } },
                                        [_c("p", [_vm._v("~~暂无数据~~")])]
                                      )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.availableclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t  \t" +
                                  _vm._s(
                                    item.inStockInventory
                                      ? item.inStockInventory
                                      : "0"
                                  ) +
                                  "\n\t\t\t\t\t\t\t  "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "650",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "tableclass",
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                border: "0",
                                cellpadding: "0",
                                cellspacing: "0"
                              }
                            },
                            [
                              _c("thead", [
                                _c("th", { staticStyle: { width: "150px" } }, [
                                  _vm._v("后续备货明细")
                                ]),
                                _c("th", { staticStyle: { width: "150px" } }, [
                                  _vm._v("在途")
                                ]),
                                _c("th", { staticStyle: { width: "150px" } }, [
                                  _vm._v("在厂(在产+成品)")
                                ]),
                                _c("th", { staticStyle: { width: "15" } }, [
                                  _vm._v("询单")
                                ]),
                                _c("th", { staticStyle: { width: "150px" } }, [
                                  _vm._v("非在库预售差异")
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(item.availablelist, function(i) {
                                  return item.availablelist.length != 0
                                    ? _c(
                                        "tr",
                                        { staticStyle: { height: "40px" } },
                                        [
                                          _c("td", [
                                            _vm._v(_vm._s(i.stockDetails))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.shippmentInventory))
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                i.inProductionNumber +
                                                  i.cargoQuantity
                                              ) +
                                                "(" +
                                                _vm._s(i.inProductionNumber) +
                                                "+" +
                                                _vm._s(i.cargoQuantity) +
                                                ")"
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(i.purchaseOrderItemDraft)
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(i.subsequentReady))
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "tr",
                                        { staticStyle: { height: "40px" } },
                                        [_c("p", [_vm._v("~~暂无数据~~")])]
                                      )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.subsequentclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.subsequentReady))]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("td", [
                    _vm._v(
                      "\n\t\t    \t\t\t\t" +
                        _vm._s(item.subsequentReadyAll) +
                        "\n\t\t    \t\t\t"
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "600",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.handleClick },
                              model: {
                                value: _vm.activeName,
                                callback: function($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "询单", name: "first" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "tableclass",
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0"
                                      }
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("数量")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("日期")]
                                        )
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(item.isscheduledlist, function(
                                          i
                                        ) {
                                          return item.isscheduledlist.length !=
                                            0
                                            ? _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(_vm._s(i.number))
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(i.detailDate))
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v("~~暂无数据~~")
                                                  ])
                                                ]
                                              )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "非询单", name: "second" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "tableclass",
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0"
                                      }
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("数量")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("日期")]
                                        )
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(item.notscheduledlist, function(
                                          i
                                        ) {
                                          return item.notscheduledlist.length !=
                                            0
                                            ? _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(_vm._s(i.number))
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(i.orderDateTime)
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v("~~暂无数据~~")
                                                  ])
                                                ]
                                              )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("p", [
                            _vm._v("总计：" + _vm._s(item.scheduledReceipt))
                          ]),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.scheduledclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.scheduledReceipt))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "600",
                            trigger: "click"
                          }
                        },
                        [
                          _c("div", { staticClass: "nottab" }, [
                            _c(
                              "table",
                              {
                                staticClass: "tableclass",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center"
                                },
                                attrs: {
                                  border: "0",
                                  cellpadding: "0",
                                  cellspacing: "0"
                                }
                              },
                              [
                                _c("thead", [
                                  _c(
                                    "th",
                                    { staticStyle: { width: "150px" } },
                                    [_vm._v("数量")]
                                  ),
                                  _c(
                                    "th",
                                    { staticStyle: { width: "150px" } },
                                    [_vm._v("日期")]
                                  ),
                                  _c(
                                    "th",
                                    { staticStyle: { width: "150px" } },
                                    [_vm._v("预计到库日期")]
                                  )
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(item.shippmentlist, function(i) {
                                    return item.shippmentlist.length != 0
                                      ? _c(
                                          "tr",
                                          { staticStyle: { height: "40px" } },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(i.number))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(i.detailDate))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(i.expectedDate))
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "tr",
                                          { staticStyle: { height: "40px" } },
                                          [_c("p", [_vm._v("~~暂无数据~~")])]
                                        )
                                  }),
                                  0
                                )
                              ]
                            )
                          ]),
                          _c("p", [
                            _vm._v("总计：" + _vm._s(item.shippmentInventory))
                          ]),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.shippmentclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.shippmentInventory))]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "600",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.handleClick },
                              model: {
                                value: _vm.activeNameagain,
                                callback: function($$v) {
                                  _vm.activeNameagain = $$v
                                },
                                expression: "activeNameagain"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "货妥", name: "first" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "tableclass",
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0"
                                      }
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("数量")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("日期")]
                                        )
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          item.inFactoryDetailList,
                                          function(i) {
                                            return item.inFactoryDetailList
                                              .length != 0
                                              ? _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      height: "40px"
                                                    }
                                                  },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(i.cargoQuantity)
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(i.detailDate)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      height: "40px"
                                                    }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v("~~暂无数据~~")
                                                    ])
                                                  ]
                                                )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "在产", name: "second" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "tableclass",
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0"
                                      }
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("数量")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("日期")]
                                        )
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          item.inProductionDetailList,
                                          function(i) {
                                            return item.inProductionDetailList
                                              .length != 0
                                              ? _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      height: "40px"
                                                    }
                                                  },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(i.cargoQuantity)
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(i.detailDate)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      height: "40px"
                                                    }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v("~~暂无数据~~")
                                                    ])
                                                  ]
                                                )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("p", [
                            _vm._v("总计：" + _vm._s(item.inFactoryInventory))
                          ]),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.inFactoryclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.inFactoryInventory))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "600",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.handleClick },
                              model: {
                                value: _vm.activeName,
                                callback: function($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName"
                              }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "货妥", name: "first" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "tableclass",
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0"
                                      }
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("确认书号")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("确认书数量")]
                                        )
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(item.inFactorylist, function(i) {
                                          return item.inFactorylist.length != 0
                                            ? _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(i.confirmNumbers)
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(i.amount))
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v("~~暂无数据~~")
                                                  ])
                                                ]
                                              )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "在产", name: "second" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "tableclass",
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0"
                                      }
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("确认书号")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "150px" } },
                                          [_vm._v("确认书数量")]
                                        )
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(item.inProductionlist, function(
                                          i
                                        ) {
                                          return item.inProductionlist.length !=
                                            0
                                            ? _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(i.confirmNumbers)
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(i.amount))
                                                  ])
                                                ]
                                              )
                                            : _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    height: "40px"
                                                  }
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v("~~暂无数据~~")
                                                  ])
                                                ]
                                              )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #409eff"
                              },
                              attrs: { slot: "reference" },
                              on: {
                                click: function($event) {
                                  return _vm.confirmclick(item, index)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(item.confirmNumbers))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "paging",
            staticStyle: {
              "margin-top": "20px",
              float: "right",
              height: "30px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 50],
                    "page-size": _vm.pageSize,
                    layout: "sizes,total, prev, pager, next",
                    total: this.allpage
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                    "update:currentPage": function($event) {
                      _vm.currentPage = $event
                    },
                    "update:current-page": function($event) {
                      _vm.currentPage = $event
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("产品编号")]),
      _c("th", [_vm._v("物料编号")]),
      _c("th", [_vm._v("在库可用库存")]),
      _c("th", [_vm._v("后续备货总量(非在库)")]),
      _c("th", [_vm._v("可用备货总量(含询单)")]),
      _c("th", [_vm._v("后续补货数量")]),
      _c("th", [_vm._v("在途")]),
      _c("th", [_vm._v("在厂")]),
      _c("th", [_vm._v("确认书")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }