var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container newboard" }, [
    _c("div", { staticClass: "commoncontent" }, [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
              _c("el-autocomplete", {
                staticClass: "input_single w200 mr10",
                attrs: {
                  placeholder: "请输入产品编号",
                  "fetch-suggestions": _vm.querySearchAsync
                },
                on: {
                  select: function($event) {
                    return _vm.handleSelect()
                  }
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("时间：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.value1,
                  callback: function($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "actions_btn_wrap down t_right" },
          [
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchclick }
            }),
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-download",
                circle: "",
                title: "下载"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "container_table table_part clearfix mt20 w100p" },
        [
          _c("singleTable", {
            attrs: {
              tableList: _vm.newboardList,
              tableLeaderFieldsList: _vm.leaderFieldsList,
              tableTailFieldsList: _vm.tailFieldsList,
              tableFieldsList: _vm.fieldsList,
              tableLoading: _vm.tableLoading
            },
            on: { popoverHandle: _vm.popoverHandle }
          }),
          _c(
            "div",
            {
              staticClass: "paging",
              staticStyle: {
                "margin-top": "20px",
                float: "right",
                height: "30px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 50],
                      "page-size": _vm.pageSize,
                      layout: "sizes,total, prev, pager, next",
                      total: this.allpage
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }